import React from 'react'
const Button = ({ hoverColor = "hover:bg-[#101010]", title, hoverTextColor = "hover:text-[#DCDCDC]", color = "bg-[#DCDCDC]", textColor = 'text-[#101010]', font = "font-semibold", size = "",
  px = "px-6", py = "py-2", onClick = () => { return } }) => {
  return (
    <button
      className={`
            ${color}
            ${py}
            ${px}
            ${size}
            rounded-full
            ${textColor}
            ${font}
            ${hoverColor}
            ${hoverTextColor}
            `}
      onClick={onClick}
    >{title}</button>
  )
}
export default Button;