import React, { useEffect, useState } from 'react';
import { updateShowLoader, updateShowBackDropLoader } from '../../redux/features/userSlice';
import { sweetNotification } from '../Common/sweetAlert';
import { getBlogInfo } from '../../redux/features/adminSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const ResearchCardPage = () => {
    const dispatch = useDispatch();
    const [blogData, setBlogData] = useState({});
    const params = useParams();

    const fetchBlog = () => {
        dispatch(updateShowLoader(true));
        dispatch(getBlogInfo(params.blogId))
            .then((result) => {
                dispatch(updateShowLoader(false));
                if (result && !result.payload.hasError) {
                    setBlogData({ ...result.payload.data.blog });
                } else {
                    sweetNotification(true, result.payload.msg);
                }
            })
            .catch(error => {
                dispatch(updateShowLoader(false));
                sweetNotification(true, 'Something went wrong');
            });
    };


    useEffect(() => {
        fetchBlog();
        // dispatch(getAllBlog()).then(result => {
        //     let fetchedBlog = result.payload.data.blogs;
        //     fetchedBlog.map((blog) => {
        //         if (params.blogId === blog._id) {
        //             setBlogData(blog)
        //         }
        //     })
        // })
    }, [params.blogId]);


    return (
        <div className=' m-4 p-2 bg-[#151617] rounded-[20px]'>
            <span className='text-4xl font-semibold gradient'>{blogData.title}</span>
            {/* <img src={`${process.env.REACT_APP_ENV === 'development' ? 'http://localhost:5000' : window.location.origin}${blogData.blogImage}`} alt={blogData.title} /> */}
            <div className='gradient nexa-Heavy-front border-t-2 pt-2' dangerouslySetInnerHTML={{ __html: blogData.content }} >
                {/* <span className='' dangerouslySetInnerHTML={{ __html: blogData.content }} />  */}
                </div>

        </div>
    );
}

export default ResearchCardPage