import React from 'react'
const Input = ({
  feedback,
  color = "bg-transparent",
  px = "px-4",
  py = "py-2",
  size = "text-lg", ...props }) => {
  return (
    <>
      <input
        {...props}
        className={`${color} border w-[100%] ${px} ${py} ${size} rounded-full`} />
      {feedback && feedback[0] && feedback[1] && (
        <span className="text-red-500">{feedback[0]}</span>
      )}
    </>
  )
}
export default Input;