import React, { useState, useEffect } from 'react';
import Input from '../Common/Input';
import RichTextEditor from '../Common/RichTextEditor';
import Button from '../Common/Button';
import { updateShowLoader, updateShowBackDropLoader } from '../../redux/features/userSlice';
import { createBlog, getBlogInfo, editBlog } from '../../redux/features/adminSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Image from '../../assets/images/NoImageAvailable.png'
import { sweetNotification, sweetToast } from '../Common/sweetAlert';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const blogSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
});

const CreateBlog = () => {

    let renderCase = 'new';
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [editorState, setEditorState] = useState('');
    const [blog, setBlog] = useState({});

    if (params.blogId) {
        renderCase = 'edit';
    }

    const fetchBlog = () => {
        dispatch(updateShowLoader(true));
        dispatch(getBlogInfo(params.blogId))
            .then((result) => {
                dispatch(updateShowLoader(false));
                if (result && !result.payload.hasError) {
                    setBlog({ ...result.payload.data.blog });
                    setEditorState(result.payload.data.blog.content);
                } else {
                    sweetNotification(true, result.payload.msg);
                }
            })
            .catch(error => {
                dispatch(updateShowLoader(false));
                sweetNotification(true, 'Something went wrong');
            });
    };

    useEffect(() => {
        renderCase === 'edit' && fetchBlog();
    }, [params.blogId]);


    const { values, errors, touched, handleBlur, handleChange, submitForm, resetForm, setFieldValue } =
        useFormik({
            initialValues: {
                title: blog?.title || '',
                blogImage: blog?.blogImage || '',
                content: blog?.content || '',
                file: null
            },
            validationSchema: blogSchema,
            enableReinitialize: true,
            onSubmit: async (values) => {

                const body = {
                    title: values.title,
                    content: editorState,
                    blogImage: values.blogImage
                };

                const formData = new FormData();
                formData.append('file', values.file);
                Object.keys(body).forEach(key => {
                    formData.append(key, body[key]);
                });

                dispatch(updateShowBackDropLoader(true));
                dispatch(
                    renderCase === 'new' ? createBlog(formData) : editBlog({ blogId: params.blogId, body: formData })
                )
                    .then(resp => {
                        dispatch(updateShowBackDropLoader(false));
                        if (resp && !resp.payload.hasError) {
                            resetForm();
                            sweetToast(false, resp.payload.msg);
                            navigate('/admin/bloglist');
                        } else {
                            sweetNotification(true, resp.payload.msg);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        dispatch(updateShowBackDropLoader(false));
                        sweetNotification(true, 'Something went wrong');
                    });
            },
        });

    const handleEditorStateChange = (state) => {
        setEditorState(state);
    };

    return (
        <div className='flex flex-col items-center justify-center p-5 gap-3'>
            <div>
                <p className='text-3xl gradient nexa-Heavy-front'>
                    {renderCase === 'new' ? 'Create New Blog' : 'Edit Blog'}
                </p>
            </div>
            <div className='w-4/5 lg:w-1/2 shadow-[0px_4px_20px_2px_rgba(255,_255,_255,_0.76)] rounded-[40px] text-white flex flex-col gap-3 py-4 px-4'>
                <div className=' sticky m-2 w-auto flex flex-col gap-2'>

                    <div className="w-auto xl:w-1/2 flex items-center justify-center sticky top-0 z-10">
                        {values.file && typeof values.file === 'object' ? (
                            <img src={URL.createObjectURL(values.file)} alt=""
                                className='bg-red-600 border-cyan-700 border-4 cursor-pointer h-40 w-40 absolute rounded-full' />
                        ) : (
                            <img
                                src={values.blogImage ? `${process.env.REACT_APP_ENV === 'development' ? 'http://localhost:5000' : window.location.origin}${blog.blogImage}` : Image}
                                alt=""
                                className='bg-red-600 border-cyan-700 border-4 h-40 w-40 absolute rounded-full' />
                        )}

                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg, .gif"
                            name="file"
                            className='h-40 w-40 border-white opacity-0 cursor-pointer '
                            onChange={(event) => {
                                setFieldValue("file", event.target.files[0]);
                            }}
                            feedback={[errors.file, touched.file]}
                        />
                    </div>
                    <Input
                        type="text"
                        name="title"
                        placeholder="Write your blog title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        feedback={[errors.title, touched.title]}
                    />
                </div>

                <div>
                    <RichTextEditor onEditorStateChange={handleEditorStateChange} initialContent={values.content} />
                </div>

                <div className='flex items-center justify-center gap-2'>
                    <Button onClick={submitForm} title={renderCase === 'new' ? 'Create' : 'Save'} size="sm" />
                    <Button title="Cancel" size="sm" onClick={() => navigate('/admin/bloglist')} />
                </div>
            </div>
        </div>
    );
};

export default CreateBlog;
