import React from 'react';
import { DateFormat } from '../../ApplicationHelper';
import Button from "../Common/Button";

const BlogListRow = ({ blog, deleteBtnClickHandler, blogInfoBtnClickHandler }) => {

    return (
        <tr className="border-t border-gray-700">
            <td >
                <div className='flex  items-center'>
                    <img className=" rounded-full h-10  w-10 border p-1" src={`${process.env.REACT_APP_ENV === 'development' ? 'http://localhost:5000' : window.location.origin}${blog.blogImage}`} />
                </div>
            </td>
            <td className='whitespace-normal'>{blog.title}</td>
            <td >{DateFormat(blog.createdOn)}</td>
            <td >{DateFormat(blog.upupdatedAtdat)}</td>

            <td className=" flex gap-3 my-2">
                <Button title="Blog info" size="sm" onClick={() => blogInfoBtnClickHandler(blog._id)} />
                <Button title="Delete" size="sm" onClick={() => deleteBtnClickHandler(blog._id)} />
            </td>
        </tr >
    );
};

export default BlogListRow;
