import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/userSlice";
import generalSlice from "./features/generalSlice";
import adminSlice from "./features/adminSlice";


const Store = configureStore({
    reducer:{
        userStore: userSlice.reducer,
        generalStore: generalSlice.reducer,
        adminStore: adminSlice.reducer,
    },
});

export default Store;