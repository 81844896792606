import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/fonts.css';
import './assets/styles/customStyle.css'
import App from './App';
import { Provider } from "react-redux";
import Store from "./redux/Store";
import { initialFetch } from './redux/features/userSlice';
Store.dispatch(initialFetch());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <App />
    </Provider>
);
