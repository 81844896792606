import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AdminService from "../../services/admin.service";
const adminService = new AdminService();

export const getUserInfo = createAsyncThunk("getUserInfo", async (userId) => {
  const response = await adminService.getUserInfo(userId);
  return response.data;
});
export const getUsersList = createAsyncThunk("getUsersList", async () => {
  const response = await adminService.getUsersList();
  return response.data;
});
export const updateUserState = createAsyncThunk("updateUserState", async (data) => {
  const response = await adminService.updateUserState(data);
  return response.data;
});
export const userReminderMessage = createAsyncThunk("userReminderMessage", async (data) => {
  const response = await adminService.userReminderMessage(data);
  return response.data;
});
export const createNewUser = createAsyncThunk("createNewUser", async (data) => {
  const response = await adminService.createNewUser(data);
  return response.data;
});
export const editUser = createAsyncThunk("editUser", async (data) => {
  const response = await adminService.editUser(data.userId, data.body);
  return response.data;
});
export const deleteUser = createAsyncThunk("deleteUser", async (userId) => {
  const response = await adminService.deleteUser(userId);
  return response.data;
});
export const createBlog = createAsyncThunk(
  'createBlog',
  async (data) => {
    const response = await adminService.createBlog(data);
    return response.data;
  });
export const getAllBlog = createAsyncThunk(
  'getAllBlog',
  async () => {
    const response = await adminService.getAllBlog();
    return response.data;
  });
export const deleteBlog = createAsyncThunk("deleteBlog", async (blogId) => {
  const response = await adminService.deleteBlog(blogId);
  return response.data;
});
export const getBlogInfo = createAsyncThunk("getBlogInfo", async (blogId) => {
  const response = await adminService.getBlogInfo(blogId);
  return response.data;
});
export const editBlog = createAsyncThunk("editBlog", async (data) => {
  const response = await adminService.editBlog(data.blogId, data.body);
  return response.data;
});

const initialState = {
  userInfo: {},
  users: [],
  blogs: [],
  error: null,
  status: "idle",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateUsers(state, action) {
      state.users = state.users.filter(
        (user) => user._id !== action.payload.userId
      );
    },
    updateBlogs(state, action) {
      state.blogs = state.blogs.filter(
        (blog) => blog._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.hasError) {
          state.users = action.payload.data.users;
        }
      })
      .addCase(getUsersList.rejected, (state) => {
        state.status = "failed";
        state.error = true;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.hasError) {
          state.userInfo = action.payload.data.user;
        }
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.status = "failed";
        state.error = true;
      })
      .addCase(getAllBlog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllBlog.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.hasError) {
          state.blogs = action.payload.data.blogs;
        }
      })
      .addCase(getAllBlog.rejected, (state) => {
        state.status = "failed";
        state.error = true;
      });
  },
});

export const { updateUsers, updateBlogs } = adminSlice.actions;
export default adminSlice;
