import Service from "./service";

class AdminService extends Service {
  constructor() {
    super();
  }

  async getUsersList() {
    return await this.ApiClient.get("/admin/users");
  }
  async createNewUser(data) {
    return await this.ApiClient.post("/admin/users/create", data);
  }
  async editUser(userId, data) {
    return await this.ApiClient.put(`/admin/users/${userId}/edit`, data);
  }
  async deleteUser(userId) {
    return await this.ApiClient.delete(`/admin/users/${userId}/delete`);
  }
  async getUserInfo(userId) {
    return await this.ApiClient.get(`/admin/users/${userId}`);
  }
  async updateUserState(data) {
    return await this.ApiClient.post("/admin/users/updatestate", data);
  }
  async userReminderMessage(data) {
    return await this.ApiClient.post("/admin/users/userremindermessage", data);
  }
  async createBlog(data) {
    return await this.ApiClient.post("/admin/blogs/create", data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 120000
      }
    );
  }
  async getAllBlog() {
    return await this.ApiClient.get(`/admin/blogs`);
  }
  async deleteBlog(blogId) {
    return await this.ApiClient.delete(`/admin/blogs/${blogId}/delete`);
  }
  async getBlogInfo(blogId) {
    return await this.ApiClient.get(`/admin/blogs/${blogId}`);
  }
  async editBlog(blogId, data) {
    return await this.ApiClient.put(`/admin/blogs/${blogId}/edit`, data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 120000
      }
    );
  }
}

export default AdminService;
