import Swal from "sweetalert2";
import './SweetAlert.css';

export const sweetNotification = (hasError = false, message = 'No Message', time = 2000) => {
  if(typeof hasError !== 'boolean'){
    hasError = false;
  }
  if(typeof message !== 'string'){
    message = 'Something went Wrong';
  }
  if(typeof time !== 'boolean'){
    time = 2000;
  }

    Swal.fire({
      position: "center",
      icon: hasError ? 'error' : 'success',
      title: message,
      showConfirmButton: false,
      timer: time
    });
  }

const toast = Swal.mixin({
  toast: true,
  position: 'top',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
});

export const sweetToast = (hasError = false, message = 'success') => {
  return toast.fire({
    icon: hasError ? 'error' : 'success',
    title: message
  });
}