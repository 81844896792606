import moment from "moment";

export const DateFormat = (date) => {
    return moment(date).format("MM-DD-YYYY")
};

export const Sorting = (data, sortType, key) => {
    const sortedData = [...data].sort((a, b) => {
        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
            return a[key].localeCompare(b[key]);
        }
        throw new Error('Unsupported data type for sorting');
    });
    
    return sortType === "asc" ? sortedData : sortedData.reverse();
};