import React, { useState, useEffect } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const RichTextEditor = ({ initialContent, onEditorStateChange }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    //const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        if (initialContent) {
            // Convert HTML string to plain text for initial display
            const blocksFromHTML = convertFromHTML(initialContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            // Set initial editor state with converted ContentState
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [initialContent]);

    // const handleFocus = () => {
    //     setIsEditable(true);
    // };

    // const handleBlur = () => {
    //     setIsEditable(false);
    // };

    const handleEditorStateChange = (state) => {
        setEditorState(state);
        const htmlContent = draftToHtml(convertToRaw(state.getCurrentContent()));
        onEditorStateChange(htmlContent);
    };

    const toolbarOptions = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
        },
        fontSize: {
            options: [8, 10, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify'],
        },
    };

    return (
        <div className="editor-wrapper">
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorStateChange}
                toolbar={toolbarOptions}
                toolbarStyle={{ color: '#000000' }} // Debugging inline styles
                toolbarClassName="sticky-toolbar"
                wrapperClassName="demo-wrapper"
                editorClassName="editor-content"
            />

        </div>
    );
};

export default RichTextEditor;
