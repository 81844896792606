import React from 'react';
import SearchIcon from "../../assets/images/search-icon.svg";

const Search = ({
  placeholder,
  searchQuery,
  HandleSearchChange = () => { return }
}) => {

  return (
    <div className='bg-[#151718] h-10 flex p-1 rounded-full'>
      <img src={SearchIcon} alt='none' className='h-8 w-8' />
      <input
        className='pl-2 placeholder:text-[10px] placeholder:font-bold mx-1 outline-none bg-transparent text-white'
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => HandleSearchChange(e)}
      />
    </div>

  )
}

export default Search