import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminProtectedRoutes = () => {

  const location = useLocation();
  const { user, status } = useSelector((state) => state.userStore);

  return status === "succeeded" && user.isAdmin ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  );
};

export default AdminProtectedRoutes;