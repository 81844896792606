import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateShowBackDropLoader, updateShowLoader } from '../../redux/features/userSlice';
import Swal from 'sweetalert2';
import { sweetNotification, sweetToast } from '../Common/sweetAlert';
import { getAllBlog, deleteBlog, updateBlogs, } from "../../redux/features/adminSlice";
import Button from '../Common/Button';
import SearchBox from '../Common/Search';
import { useNavigate } from 'react-router';
import BlogListRow from './BlogListRow';


const BlogList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { blogs, users, status: userListFetchstatus } = useSelector((state) => state.adminStore);
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        dispatch(getAllBlog())

    }, []);

    const filteredBlogs = useMemo(() =>
        blogs.filter(blog =>
            blog.title.toLowerCase().includes(searchQuery.toLowerCase())
        ),
        [blogs, searchQuery]
    );

    /** Handlers */
    const blogInfoBtnClickHandler = (blogId) => {
        navigate(`/admin/bloglist/${blogId}`);
    }



    const HandleSearchChange = (e) => {
        e.preventDefault();
        setSearchQuery(e.target.value);
    }

    const deleteBtnClickHandler = (blogId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(updateShowBackDropLoader(true));
                dispatch(deleteBlog(blogId))
                    .then((resp) => {
                        dispatch(updateShowBackDropLoader(false));
                        if (resp && !resp.payload.hasError) {
                            sweetToast(false, resp.payload.msg);
                            dispatch(updateBlogs(blogId));
                        } else {
                            sweetNotification(true, resp.payload.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        dispatch(updateShowBackDropLoader(false));
                        sweetNotification(true, "Something went wrong");
                    });
            }
        });
    };

    if (userListFetchstatus === 'loading' && !users.length) {
        dispatch(updateShowLoader(true));
        return;
    } else {
        dispatch(updateShowLoader(false));
    }

    return (
        <div className=" p-3">

            <div className="flex justify-between mb-4">
                <SearchBox
                    placeholder="Search Blogs..."
                    searchQuery={searchQuery}
                    HandleSearchChange={HandleSearchChange}
                />
                <Button title="ADD New Blog" size="sm" onClick={() => navigate("/admin/bloglist/create")} />
            </div>

            <div className="overflow-x-auto">
                <table className="w-full  text-white ">
                    <thead className='bg-[#121314] border-t border-gray-700 nexa-ExtraLight-front text-[#A3A3A3]'>
                        <tr>
                            <th >Image</th>
                            <th className='w-3/5'>Title</th>
                            <th >Created On</th>
                            <th >Updated On</th>
                            <th >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBlogs.map((blog) => (
                            <BlogListRow
                                key={blog._id}
                                blog={blog}
                                deleteBtnClickHandler={deleteBtnClickHandler}
                                blogInfoBtnClickHandler={blogInfoBtnClickHandler}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default BlogList;