import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GeneralService from "../../services/general.service";
const generalService = new GeneralService();


export const getDashboardData = createAsyncThunk(
    'getDashboardData',
    async () => {
        const response = await generalService.getDashboardData();
        return response.data;
    }
);

export const getTop10Trades = createAsyncThunk(
    'getTop10Trades',
    async (data) => {
        const response = await generalService.getTop10Trades(data);
        return response.data;
    }
);

export const getTradeData = createAsyncThunk(
    'getTradeData',
    async (data) => {
        const response = await generalService.getTradeData(data);
        return response.data;
    }
);

export const getTradeDetail = createAsyncThunk(
    'getTradeDetail',
    async (data) => {
        const response = await generalService.getTradeDetail(data.tradeId, data.queryParams);
        return response.data;
    }
);

export const getTradeHistoryChartData = createAsyncThunk(
    'getTradeHistoryChartData',
    async (data) => {
        const response = await generalService.getTradeHistoryChartData(data.code, data.queryParams,data.signal );
        return response.data;
    }
);

export const getSectorData = createAsyncThunk(
    'getSectorData',
    async () => {
        const response = await generalService.getSectorData();
        return response.data;
    }
);

export const getSectorDetail = createAsyncThunk(
    'getSectorDetail',
    async (data) => {
        const response = await generalService.getSectorDetail(data.sectorName, data.queryParams);
        return response.data;
    }
);

export const getCompaniesData = createAsyncThunk(
    'getCompaniesData',
    async (data) => {
        const response = await generalService.getCompaniesData(data);
        return response.data;
    }
);

export const getCompaniesDetail = createAsyncThunk(
    'getCompaniesDetail',
    async (data) => {
        const response = await generalService.getCompaniesDetail(data.companyId);
        return response.data;
    }
);

export const getWatchlistData = createAsyncThunk(
    'getWatchlistData',
    async (data) => {
        const response = await generalService.getWatchlistData(data);
        return response.data;
    }
);
export const deleteWatchlistRowData = createAsyncThunk("deleteWatchlistRowData", async (code) => {
    const response = await generalService.deleteWatchlistRowData(code);
    return response.data;
});


export const getNewsData = createAsyncThunk(
    'getNewsData',
    async (data) => {
        const response = await generalService.getNewsData(data);
        return response.data;
    }
);

export const getGptResponse = createAsyncThunk(
    'getGptResponse',
    async (data) => {
        const response = await generalService.getGptResponse(data);
        return response.data;
    }
);

const initialstate = {
    dashboardData: { latestTrades: [], activeSectors: [], top10Trades: [] },
    // tradeData: { count: 0, volume: 0, companies: 0, list: [] },
    tradeData: { count: 0, volume: 0, companies: 0, list: [], pagesLength:1, sectorDropdownList: {} },
    tradeDetails: { details: {}, fillingSummary: {} },
    sectorData: [],
    sectorDetails: {list:[]},
    companiesData: { trades: 0, filings: 0, volume: 0, issuers: 0, pagesLength:1, list: [] },
    companiesDetails: { details: {}, trade: {} },
    watchlistData: { list: [] },
    newsData: { press: [], research: [], tweets: [] },
    status: 'idle',
    error: null,
}

const generalSlice = createSlice({
    name: "general",
    initialState: initialstate,
    reducers: {
        updateDashboardData(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.dashboardData = action.payload.data;
                    break;
                case 'updateTrades':
                    state.dashboardData.latestTrades = action.payload.data;
                    break;
                case 'updateSectors':
                    state.dashboardData.activeSectors = action.payload.data;
                    break;
                case 'updateNews':
                    state.dashboardData.latestNews = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateNewsData(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.newsData = action.payload.data;
                    break;
                case 'updatePress':
                    state.newsData.press = action.payload.data;
                    break;
                case 'updateResearch':
                    state.newsData.research = action.payload.data;
                    break;
                case 'updateTweets':
                    state.newsData.tweets = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateTradeData(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.tradeData = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateTradeDetails(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.tradeDetails = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateSectorData(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.sectorData = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateSectorDetails(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.sectorDetails = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateCompaniesData(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.companiesData = action.payload.data;
                    break;
                default: return state;
            }
        },
        sortingData(state, action) {

            const sortedList = state.tradeData.list.sort((a, b) => {
                if (typeof a[action.payload.key] === 'string' && typeof b[action.payload.key] === 'string') {
                    return a[action.payload.key].localeCompare(b[action.payload.key]);
                }
            });

            state.tradeData.list = action.payload.type === "asc" ? sortedList : sortedList.reverse();
        },
        updateCompaniesDetails(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.companiesDetails = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateWatchlistData(state, action) {
            switch (action.payload.type) {
                case 'updateAll':
                    state.watchlistData = action.payload.data;
                    break;
                default: return state;
            }
        },
        updateAuthSliceErrorStatus(state, action) {
            state.error = action.payload;
        },
        updateRequestStatus(state, action) {
            state.status = action.payload;
        },

    },
});

export const { updateAuthSliceErrorStatus, updateDashboardData, updateNewsData,
    updateTradeData, updateSectorData, updateCompaniesData, updateRequestStatus,
    updateWatchlistData, updateTradeDetails, updateSectorDetails,
    updateCompaniesDetails, sortingData } = generalSlice.actions;
export default generalSlice;