import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserProtectedRoutes = () => {

  const location = useLocation();
  const { user, isAuthenticated, status } = useSelector(state => state.userStore);

  if(isAuthenticated && user.isAdmin ){
    return !window.location.pathname.includes('/admin') ? <Navigate to="/admin/users" /> : <Outlet />
  } else if(isAuthenticated && window.location.pathname === '/'){
   return <Navigate to="/dashboard" />
  }else if(status === 'loading'){
    return;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default UserProtectedRoutes;